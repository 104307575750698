import * as React from "react";
import { memo } from "react";
const SvgGiftCardsIcon = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 25,
    height: 24,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { stroke: "#F68A1F", clipPath: "url(#7f5e57f3-6fc0-4fa2-9736-3ddb084e7e9f-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeLinecap: "round",
      strokeWidth: 1.5,
      d: "M7.98 2h9.63M20.43 4.57H5.15"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeWidth: 2,
      d: "M21.84 7.3H3.74c-1.1 0-2 .9-2 2v11.43c0 1.1.9 2 2 2h18.1c1.1 0 1.99-.9 1.99-2V9.3c0-1.1-.9-1.99-2-1.99Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "M10.15 22.72V7.3M1.74 15.36h22.1"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeWidth: 2,
      d: "M14.24 14.34c-1.02 1.02-4.1 1.02-4.1 1.02s0-3.07 1.03-4.09a2.17 2.17 0 1 1 3.07 3.07Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeWidth: 2,
      d: "M9.13 11.27c1.02 1.02 1.02 4.09 1.02 4.09s-3.07 0-4.1-1.02a2.17 2.17 0 1 1 3.08-3.07Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      strokeLinecap: "round",
      strokeWidth: 2,
      d: "m10.15 15.36 3.59 3.6M6.57 18.95l3.58-3.59"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "7f5e57f3-6fc0-4fa2-9736-3ddb084e7e9f-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M.74 0h24v24h-24z" })))
);
const Memo = memo(SvgGiftCardsIcon);
export default Memo;
